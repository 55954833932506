// src/firebase/firebase.config.js
// This file uses ES6 modules (ESM)

// Import Firebase modules for initialization and authentication
import { initializeApp, getApps, getApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

/**
 * Firebase configuration object.
 *
 * Contains all necessary Firebase credentials and identifiers.
 * Values are sourced from environment variables for security and flexibility.
 */
const firebaseConfig = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
  measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID,
};

/**
 * Base URL of the application.
 *
 * Used to construct OAuth redirect URIs dynamically.
 */
const baseUrl = process.env.NEXT_PUBLIC_URL || '';

/**
 * Constructs a full URL by combining the base URL and a path.
 *
 * Ensures that there is exactly one slash between the base URL and the path.
 *
 * @param {string} base - The base URL of the application.
 * @param {string} path - The path to be appended to the base URL.
 * @returns {string} - The combined full URL.
 */
function getRedirectUrl(base, path) {
  // Remove trailing slash from base URL if present
  const trimmedBase = base.endsWith('/') ? base.slice(0, -1) : base;
  // Ensure path starts with a slash
  const formattedPath = path.startsWith('/') ? path : `/${path}`;
  return `${trimmedBase}${formattedPath}`;
}

/**
 * Paths for OAuth redirect endpoints.
 *
 * These paths are defined by the authentication services and specify where
 * the user should be redirected after successful authentication.
 */
const googleRedirectPath = '/api/auth/callback/google';
const linkedInRedirectPath = '/api/auth/callback/linkedin';

/**
 * Full redirect URLs for Google and LinkedIn OAuth authentication.
 *
 * These URLs are required by OAuth services to redirect users back to the application
 * after authentication. They are constructed using the base URL and the specific paths.
 */
export const googleRedirectUrl = getRedirectUrl(baseUrl, googleRedirectPath);
export const linkedInRedirectUrl = getRedirectUrl(baseUrl, linkedInRedirectPath);

/**
 * Initializes the Firebase application.
 *
 * Checks if a Firebase app has already been initialized to prevent re-initialization,
 * which can cause errors. If not initialized, it initializes with the provided config.
 */
export const app = getApps().length === 0 ? initializeApp(firebaseConfig) : getApp();

/**
 * Initializes Firebase Authentication.
 *
 * Provides authentication methods and state management for the app.
 * Uses the initialized Firebase app instance.
 */
export const auth = getAuth(app);
