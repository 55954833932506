// src/services/auth.service.js

import { aventureApi } from '@/services/apiService/aventureApi';
import { axiosService } from "@/services/apiService/axiosService";
import { authActions } from '@/store/auth/auth.slice.js';
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { Cookies } from 'react-cookie';
import { loadingError } from './error.service.js';
import { removeFromLocalStorage } from './localStorage.service.js';
import { auth } from '../firebase/firebase.config.js';

const baseUrl = process.env.NEXT_PUBLIC_API_URL || "";

const addCookie = ({ userId, roles }) => {
  document.cookie = `isLoggedIn=${userId}; path=/; expires=Tue, 19 Jan 2038 03:14:07 GMT`;
  document.cookie = `isAdmin=${roles}; path=/; expires=Tue, 19 Jan 2038 03:14:07 GMT`;
};

const authApi = aventureApi.injectEndpoints({
  endpoints: (builder) => ({
    userSignIn: builder.mutation({
      async queryFn({ email, password, authCode, onClose }, { dispatch }) {
        try {
          const { user } = await signInWithEmailAndPassword(
            auth,
            email,
            password
          );

          const { data } = await axiosService.post(
            `${baseUrl}/app/auth/signin`,
            {
              email,
              password,
              authCode,
            },
            { headers: { Authorization: `Bearer ${user?.accessToken}` } }
          );

          dispatch(authActions.setUserIsVerify(data?.userId));
          addCookie({ userId: data?.userId, roles: data?.roles });
          localStorage.setItem("pageCount", 0);
          authCode && onClose();

          return { data };
        } catch (error) {
          !error?.toLowerCase()?.includes("firebase") &&
            dispatch(authActions.setIsNeed2FA(error.response?.data?.message));
          error.response?.data?.message !== "Need 2fa" &&
            dispatch(loadingError(error));
          return {
            error: error.response?.data?.message || error?.message || error,
          };
        }
      },
    }),

    googleAuth: builder.mutation({
      async queryFn(
        {
          access_token,
          authCode,
          onOpen,
          setProvider,
          setAccessToken,
          onClose,
        },
        { dispatch }
      ) {
        try {
          const { data } = await axiosService.post(`/app/auth/google`, {
            access_token,
            authCode,
            redirect_uri: `${process.env.NEXT_PUBLIC_URL}/api/auth/callback/google`,
          });
          dispatch(authActions.setUserIsVerify(data?.userId));
          addCookie({ userId: data?.userId, roles: data.roles });
          localStorage.setItem("pageCount", 0);
          authCode && onClose();

          return { data };
        } catch (error) {
          error.response?.data?.message === "Need 2fa" &&
            (setProvider("google"), setAccessToken(access_token), onOpen());
          error.response?.data?.message !== "Need 2fa" &&
            dispatch(loadingError(error));
          return {
            error: error.response?.data?.message || error?.message || error,
          };
        }
      },
      invalidatesTags: (res, err, arg) =>
        arg?.is_connect && [{ type: "profile" }],
    }),

    linkedInAuth: builder.mutation({
      async queryFn({ access_token, authCode, onClose }, { dispatch }) {
        try {
          const { data } = await axiosService.post(
            `/app/auth/linkedin`,
            {
              access_token,
              authCode,
              redirect_uri: `${process.env.NEXT_PUBLIC_URL}/api/auth/callback/linkedin`,
            },
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );

          dispatch(authActions.setUserIsVerify(data?.userId));
          addCookie({ userId: data?.userId, roles: data.roles });
          localStorage.setItem("pageCount", 0);
          authCode && onClose();
          return { data };
        } catch (error) {
          dispatch(authActions.setIsLinkedIn2FA(error.response?.data?.message));
          error.response?.data?.message !== "Need 2fa" &&
            dispatch(loadingError(error));
          return {
            error: error.response?.data?.message || error?.message || error,
          };
        }
      },
      invalidatesTags: (res, err, arg) =>
        arg?.is_connect && [{ type: "profile" }],
    }),

    userSignUp: builder.mutation({
      async queryFn(options, { dispatch }) {
        try {
          const { data } = await axiosService.post(`/app/auth/signup`, options);

          return { data };
        } catch (error) {
          dispatch(loadingError(error));
          return {
            error: error.response?.data?.message || error?.message || error,
          };
        }
      },
    }),

    addPassword: builder.mutation({
      async queryFn(options, { dispatch }) {
        try {
          const { email, password, isForgot } = options;
          const { user } =
            !isForgot &&
            (await createUserWithEmailAndPassword(auth, email, password));

          const { data } = await axiosService.post(
            `${baseUrl}/app/auth/set-password`,
            { ...options },
            {
              headers: !isForgot
                ? {
                    Authorization: `Bearer ${user?.accessToken}`,
                  }
                : undefined,
            }
          );

          return { data: data || null };
        } catch (error) {
          !error?.toLowerCase()?.includes("firebase") &&
            dispatch(loadingError(error));
          return {
            error: error.response?.data?.message || error?.message || error,
          };
        }
      },
    }),

    forgotPassword: builder.mutation({
      async queryFn(email, { dispatch }) {
        try {
          const { data } = await axiosService.post(
            `${baseUrl}/app/auth/forgot-password`,
            {
              email,
            }
          );
          return { data };
        } catch (error) {
          dispatch(loadingError(error));
          return { error: error?.message || error };
        }
      },
    }),

    signUpWithEmail: builder.mutation({
      async queryFn(email, { dispatch }) {
        try {
          const { data } = await axiosService.post(`${baseUrl}/app/account`, {
            email,
          });
          return { data };
        } catch (error) {
          dispatch(loadingError(error));
          return { error: error?.message || error };
        }
      },
    }),

    userSignOut: builder.mutation({
      async queryFn(_, { dispatch }) {
        try {
          const { data } = await axiosService.put(`${baseUrl}/app/auth/logout`);
          const cookies = new Cookies();
          cookies.remove("isLoggedIn", {
            path: "/",
          });
          removeFromLocalStorage("userVerify");
          document.cookie = `isLoggedIn=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
          localStorage.setItem("pageCount", 0);
          return { data };
        } catch (error) {
          dispatch(loadingError(error));
          return { error: error?.message || error };
        }
      },
    }),

    resendEmail: builder.mutation({
      async queryFn(email, { dispatch }) {
        try {
          const { data } = await axiosService.post(
            `${baseUrl}/app/auth/resend`,
            {
              email,
            }
          );
          return { data };
        } catch (error) {
          dispatch(loadingError(error));
          return { error: error?.message || error };
        }
      },
    }),

    addUserRoles: builder.mutation({
      async queryFn(roles, { dispatch }) {
        try {
          const { data } = await axiosService.patch(`${baseUrl}/app/user`, {
            ...roles,
          });

          dispatch(authActions.setUserIsVerify(data?.id));

          addCookie({ userId: data?.userId, roles: data.roles });

          return { data };
        } catch (error) {
          dispatch(loadingError(error));
          return { error: error?.message || error };
        }
      },
    }),

    freeAccountByEmail: builder.mutation({
      async queryFn(incomeData, { dispatch }) {
        try {
          const { data } = await axiosService.post(`${baseUrl}/app/account`, {
            email: incomeData?.email,
          });
          localStorage.setItem("pageCount", 0);
          return { data };
        } catch (error) {
          dispatch(loadingError(error));
          return { error: error?.message || error };
        }
      },
    }),

    getLoginByLinkCode: builder.query({
      queryFn: async (email, { dispatch }) => {
        try {
          const { data } = await axiosService.get(
            `${baseUrl}/app/auth/login-link`,
            {
              params: { email },
            }
          );

          return { data };
        } catch (error) {
          dispatch(loadingError(error));
          return { error: error?.message || error };
        }
      },
    }),

    loginByLink: builder.mutation({
      queryFn: async (code, { dispatch }) => {
        try {
          const { data } = await axiosService.post(
            `${baseUrl}/app/auth/login-link`,
            {
              code: code,
            }
          );

          dispatch(authActions.setUserIsVerify(data?.userId));
          addCookie({ userId: data?.userId, roles: data?.roles });
          localStorage.setItem("pageCount", 0);

          return { data };
        } catch (error) {
          dispatch(loadingError(error));
          return {
            error: error?.response?.data?.message || error?.message || error,
          };
        }
      },
    }),
  }),
  overrideExisting: true,
});

export const {
  useUserSignInMutation,
  useUserSignUpMutation,
  useGoogleAuthMutation,
  useLinkedInAuthMutation,
  useAddPasswordMutation,
  useForgotPasswordMutation,
  useSignUpWithEmailMutation,
  useUserSignOutMutation,
  useResendEmailMutation,
  useAddUserRolesMutation,
  useFreeAccountByEmailMutation,
  useLazyGetLoginByLinkCodeQuery,
  useLoginByLinkMutation,
} = authApi;

export default authApi;
