// src/services/error.service.js
// This file uses ES6 syntax
// TODO: identify how this error service is used: for developers or for the UI? and comment here which
import { errorActions } from '@/store/error/error.slice';

export const loadingError = (error) => (dispatch) => {
  dispatch(
    errorActions.loadError(
      error?.response
        ? error?.response?.data?.message || error?.response?.data?.error
        : error?.message || error,
    ),
  );
};

export const clearError = () => (dispatch) => {
  dispatch(errorActions.clearError());
};
