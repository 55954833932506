// src/services/apiService/axiosService.js
// DEPRECATED: This service is being deprecated beginning September 24, 2024.
// It is being gradually replaced by fetchService.js where possible going forward.
//
// Note: This service remains stable and is still used in existing code.
// Do not modify this file unless necessary for critical fixes.
//
// Please use fetchService.js for new implementations and when refactoring code
// to ensure compatibility with the Edge Runtime and modern practices.
// This file uses ES6 (ESM) syntax
import axios from "axios";

export const axiosService = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_URL,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "User-Agent":
      "Mozilla/5.0 (Windows NT 10.0; Win64; x64; rv:106.0) Gecko/20100101 Firefox/106.0",
    Referer: process.env.NEXT_PUBLIC_URL,
  },
  withCredentials: true,
});

axiosService.interceptors.request.use(
  (config) => {
    console.debug(
      `Sending ${config.method.toUpperCase()} request to: ${config.url}`
    );
    return config;
  },
  (error) => {
    console.error("Request error:", error);
    throw error;
  }
);

axiosService.interceptors.response.use(
  (response) => response,
  (error) => {
    const errorInfo = {
      url: error.config?.url,
      method: error.config?.method,
      status: error.response?.status,
      message: error.message,
      data: error.response?.data,
    };
    console.error("API Request Failed:", errorInfo);

    // Custom error object with relevant info
    const customError = new Error("API request failed");
    customError.info = errorInfo;
    throw customError;
  }
);
