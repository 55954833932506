// src/store/error/error.slice.js
// This file uses ES6 / ESM syntax

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  error: null,
};

const errorSlice = createSlice({
  name: 'error',
  initialState,
  reducers: {
    loadError: (state, action) => {
      !Array.isArray(state.error) && (state.error = []);
      state.error.push(action.payload);
    },
    clearError: (state) => {
      state.error = null;
    },
  },
});
export const errorActions = errorSlice.actions;

export default errorSlice;
